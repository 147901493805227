const i18n = JSON.parse(document.querySelector('.mfooter-accessibility [data-translations]').innerText);

document.querySelector('.mfooter-accessibility #switch_contrast').addEventListener('change', (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.body.classList.toggle('high-contrast');
    if (window.localStorage) {
        if (document.body.classList.contains('high-contrast')) {
            window.localStorage.setItem('high-contrast', 'true');
            document.body.classList.add('high-contrast');
            document.body.setAttribute('data-contrast', 'high');
            document.querySelector('.mfooter-accessibility label').setAttribute('title', i18n.highContrastOff_Label);
            document.querySelector('.mfooter-accessibility label').setAttribute('aria-label', i18n.highContrastOff_Label);
            document.querySelector('.mfooter-accessibility .switch-check').setAttribute('data-ats-accessibility-button', 'on');
            document.getElementById('switch_contrast').checked = true;
        } else {
            window.localStorage.removeItem('high-contrast');
            document.body.classList.remove('high-contrast');
            document.body.removeAttribute('data-contrast');
            document.querySelector('.mfooter-accessibility label').setAttribute('title', i18n.highContrastOn_Label);
            document.querySelector('.mfooter-accessibility label').setAttribute('aria-label', i18n.highContrastOn_Label);
            document.querySelector('.mfooter-accessibility .switch-check').setAttribute('data-ats-accessibility-button', 'off');
            document.getElementById('switch_contrast').checked = false;
        }
    }
});
if (window.localStorage && window.localStorage.getItem('high-contrast') === 'true') {
    document.body.classList.add('high-contrast');
    document.querySelector('.mfooter-accessibility label').setAttribute('title', i18n.highContrastOff_Label);
    document.querySelector('.mfooter-accessibility label').setAttribute('aria-label', i18n.highContrastOff_Label);
    document.querySelector('.mfooter-accessibility .switch-check').setAttribute('data-ats-accessibility-button', 'on');
    document.getElementById('switch_contrast').checked = true;
}
